import { system as base } from '@theme-ui/presets'

export default {
  ...base,
  colors: {
    ...base.colors,
    primary: '#1840E6',
    secondary: '#F68121',
    secondaryText: '#919191',
    modes: {
      dark: {
        text: '#fff',
        secondaryText: '#fff',
        background: '#060606',
        secondary: '#3cf',
        primary: '#e0f',
        muted: '#191919',
        highlight: '#29112c',
        gray: '#999',
        accent: '#c0f',
      },
    },
  },
  fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64, 96],
  fonts: {
    body: `Inter, sans-serif`,
    heading: `Quattrocento, serif`,
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  text: {
    link: {
      fontSize: 3,
      fontWeight: 'medium',
    },
    heading: {
      fontSize: 4,
      fontWeight: 'semiBold',
    },
    body: {
      fontSize: 2,
      fontWeight: 'regular',
      color: 'secondaryText',
    },
    body2: {
      fontSize: 1,
      fontWeight: 'regular',
    },
    body3: {
      fontSize: 3,
      fontWeight: 'regular',
    },
    tag: {
      fontSize: 1,
      fontWeight: 'semiBold',
    },
    headerMsg: {
      fontSize: [1, 3, 3],
      fontWeight: 'regular',
    },
    freeTag: {
      fontSize: 1,
      fontWeight: 'semiBold',
      textTransform: 'uppercase',
      bg: 'muted',
      px: 3,
      py: 1,
    },
  },
  cards: {
    article: {
      height: '100%',
      padding: 3,
      border: 2,
      borderColor: 'text',
      borderStyle: 'solid',
    },
  },
  buttons: {
    colorMode: {
      fontSize: 2,
      fontWeight: 'semiBold',
      color: 'text',
      backgroundColor: 'background',
      border: 2,
      borderRadius: 0,
      borderColor: 'text',
      borderStyle: 'solid',
      p: 1,
      px: 2,

      '&:focus': {
        outline: 'none',
      },
    },
    heart: {
      '&:focus': {
        outline: 'none',
      },
    },
    externalLink: {
      transition: 'all .4s ease',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  links: {
    nav: {
      p: 1,
      color: 'inherit',
      textDecoration: 'none',
      fontSize: 1,
      fontWeight: 'bold',
      bg: 'transparent',
      transitionProperty: 'background-color',
      transitionTimingFunction: 'ease-out',
      transitionDuration: '.2s',
      borderRadius: 2,
      '&:hover': {
        bg: 'muted',
      },
      '&.active': {
        color: 'primary',
        bg: 'muted',
      },
    },
  },
  forms: {
    sortSelect: {
      fontSize: 1,
      fontWeight: 'regular',
      border: 2,
      borderRadius: 0,
      borderColor: 'text',
      borderStyle: 'solid',
      width: 'auto',
      pr: 25,
    },
  },
}
